@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@550&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap");
.top-bar {
  background-color: #2f2f2f;
  height: 25px;
  color: #cfcfcf;
  font-size: .9em;
  width: 100%; }
  .top-bar a:link, .top-bar :visited, .top-bar :hover, .top-bar :active {
    color: white;
    text-decoration: none; }
  .top-bar .position-left {
    position: relative;
    height: 100%;
    float: left;
    padding-left: 10px; }
  .top-bar .position-right {
    position: relative;
    height: 100%;
    float: right;
    padding-right: 30px; }
  .top-bar .icon-element {
    padding: 0 15px 0 15px; }
  .top-bar .separator-element {
    margin: 0 0 0 10px;
    border-left: 1px solid #cfcfcf; }
  @media (max-width: 575.98px) {
    .top-bar {
      padding-top: 5px;
      font-size: .1em; }
      .top-bar .position-left {
        position: relative;
        height: 100%;
        float: left;
        padding-left: 4px; }
      .top-bar .position-right {
        position: relative;
        height: 100%;
        float: left;
        padding-right: 4px; }
      .top-bar .icon-element {
        padding: 0px; }
      .top-bar .separator-element {
        margin: 1px;
        border-left: 1px solid #cfcfcf; } }
  @media (max-width: 767.98px) {
    .top-bar {
      padding-top: 5px;
      font-size: .1em; }
      .top-bar .position-left {
        position: relative;
        height: 100%;
        float: left;
        padding-left: 4px; }
      .top-bar .position-right {
        position: relative;
        height: 100%;
        float: right;
        padding-right: 4px; }
      .top-bar .icon-element {
        padding: 0px; }
      .top-bar .separator-element {
        margin: 1px;
        border-left: 1px solid #cfcfcf; } }

.navigation-area {
  width: 100%;
  background-color: #f8f9fa; }
  .navigation-area .navbar {
    padding: 20px;
    margin-bottom: 40px;
    background-color: #fff;
    display: block;
    width: 100%; }
    .navigation-area .navbar .bwm-search {
      height: 50px;
      width: 300px !important; }
    .navigation-area .navbar .navbar-brand {
      margin-right: 30px;
      letter-spacing: 1px;
      color: #3c3c3c !important;
      font-family: 'Oswald';
      font-size: 50px;
      margin-top: -20px; }
      .navigation-area .navbar .navbar-brand img {
        height: 28px;
        margin-left: -2px; }
    .navigation-area .navbar .navbar-brand-sub {
      font-size: 15px;
      margin-top: -15px;
      padding-left: 2px;
      letter-spacing: 3px; }
    .navigation-area .navbar .nav-item {
      font-size: 14px;
      font-family: 'Oswald';
      font-size: 15px;
      letter-spacing: 1px;
      margin-left: 10px; }
    .navigation-area .navbar .btn-bwm-search {
      border-color: #3c3c3c;
      color: #3c3c3c; }
      .navigation-area .navbar .btn-bwm-search:hover, .navigation-area .navbar .btn-bwm-search:focus, .navigation-area .navbar .btn-bwm-search:active {
        background-color: transparent !important;
        border-color: #3c3c3c !important;
        color: #3c3c3c !important;
        box-shadow: none; }
    .navigation-area .navbar .nav-item {
      color: #ededed; }
    .navigation-area .navbar .dropdown-menu .dropdown-item {
      font-size: 14px; }
      .navigation-area .navbar .dropdown-menu .dropdown-item:active {
        background-color: #3c3c3c; }
    @media (max-width: 575.98px) {
      .navigation-area .navbar .navbar-brand {
        margin-right: 30px;
        letter-spacing: 1px;
        color: #3c3c3c !important;
        font-family: 'Oswald';
        font-size: 40px;
        margin-top: -20px; }
        .navigation-area .navbar .navbar-brand img {
          height: 28px;
          margin-left: -2px; }
      .navigation-area .navbar .navbar-brand-sub {
        font-size: 10px;
        margin-top: -15px;
        padding-left: 2px;
        letter-spacing: 3px; } }

.carousel-fade .carousel-item {
  display: block;
  position: absolute;
  opacity: 0;
  transition: opacity .95s ease-in-out; }
  .carousel-fade .carousel-item.active {
    opacity: 1; }

.carousel,
.carousel-inner,
.carousel-item {
  width: 100%;
  height: 100%; }

.carousel {
  position: absolute;
  top: 0;
  left: 0; }

.carousel-item:nth-child(1) {
  background-color: white; }

.carousel-item:nth-child(2) {
  background-color: white; }

.carousel-item:nth-child(3) {
  background-color: white; }

.carousel-item .carousel-caption {
  color: #5e5e5e;
  font-size: 3vw;
  font-family: 'Oswald';
  text-align: left; }

.jumbotron {
  margin-top: 20px;
  text-align: center;
  background-color: #f7f7f7; }
  .jumbotron .side-image-left {
    float: left;
    height: 30vh;
    width: 18vw;
    margin-top: -97px; }
  .jumbotron .side-image-right {
    float: right;
    height: 26vh;
    width: 18vw;
    margin-top: -63px; }
  .jumbotron .beauty-text {
    margin-top: 5px;
    font-family: 'Oswald';
    font-size: 4.5vw;
    color: white; }
  .jumbotron .product-line-h1 {
    margin-top: -10px;
    text-align: center;
    text-transform: capitalize;
    color: #5d5a5a;
    -webkit-text-stroke-width: 1px;
    font-family: 'Oswald';
    font-size: 3vw; }
  .jumbotron .product-line-sub {
    text-align: center;
    color: #5d5a5a;
    font-family: 'Abril Fatface', cursive;
    font-size: 3vw;
    text-shadow: -1px -14px 5px #bebebe; }
  @media (max-width: 575.98px) {
    .jumbotron {
      font-family: 'Oswald'; }
      .jumbotron .side-image-left {
        margin-top: -40px;
        width: 17vw;
        height: 18vh; }
      .jumbotron .side-image-right {
        margin-top: -32px;
        height: 18vw; }
      .jumbotron h1 {
        font-family: 'Oswald';
        font-size: 6vw;
        position: absolute; }
      .jumbotron .jumbotron-para {
        text-align: justify; } }

.product-line .product-line-img {
  height: 220px;
  width: 14vw;
  min-width: 220px;
  margin: 10px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }

.consult {
  margin-top: 25px;
  font-family: 'Oswald';
  background-color: #5dd5f9; }
  .consult .consult-area {
    padding: 20px; }
    .consult .consult-area .consult-title {
      font-size: 3vw;
      color: black;
      text-align: center; }
    .consult .consult-area .consult-subtitle {
      font-size: 1.5vw;
      color: black;
      text-align: center; }
    .consult .consult-area .consult-details {
      font-size: 1.1vw;
      color: black;
      text-align: center; }
    .consult .consult-area .consult-term-condition {
      font-size: 1vw;
      color: black;
      text-align: center; }
    .consult .consult-area .consult-callus {
      font-size: 2.4vw;
      color: black;
      text-align: center; }
    .consult .consult-area .consult-phone {
      font-size: 2.6vw;
      color: black;
      text-align: center; }
  .consult .consult-img {
    padding: 20px;
    width: 100%;
    height: auto; }

.footer {
  clear: both;
  position: relative;
  height: 250px;
  margin-top: 0;
  padding: 20px 0 10px 0;
  font-family: 'Oswald';
  background-color: #f5f5f5; }
  .footer .footer-widget h3 {
    margin-bottom: 30px; }
  .footer .contact-link {
    display: inline-block;
    width: 100%;
    color: #333; }
  .footer .footer-social ul {
    padding-left: 0; }
    .footer .footer-social ul li {
      list-style: none;
      float: left;
      padding: 0 10px; }
      .footer .footer-social ul li:first-child {
        padding-left: 0; }
      .footer .footer-social ul li a {
        font-size: 20px;
        color: #333; }
        .footer .footer-social ul li a:hover {
          color: red; }
  .footer .opening-time {
    padding-left: 0; }
    .footer .opening-time li {
      list-style: none; }
      .footer .opening-time li span {
        float: left;
        padding-right: 10px; }
        .footer .opening-time li span .fa-times {
          color: red; }
      .footer .opening-time li strong {
        color: red; }
  .footer .media-body a {
    color: #333; }
    .footer .media-body a:hover {
      color: red; }
  .footer .media-body span {
    color: #969696; }
  .footer .images-gellary ul {
    padding-left: 0; }
    .footer .images-gellary ul li {
      list-style: none;
      float: left;
      margin: 0 3% 2% 0;
      width: 31%;
      position: relative; }
      .footer .images-gellary ul li:nth-child(3n) {
        margin: 0 0 1%; }

.footer-bar {
  font-family: 'Oswald';
  text-align: center;
  background-color: #2f2f2f;
  height: 25px;
  color: #cfcfcf;
  font-size: .8em; }

.bwm-form {
  color: #484848;
  margin-bottom: 25px;
  font-family: 'Oswald'; }
  .bwm-form h1 {
    font-weight: 700;
    margin-bottom: 30px; }
  .bwm-form .image-container {
    position: relative; }
    .bwm-form .image-container img {
      width: 100%;
      border-radius: 5px; }
  .bwm-form label {
    font-size: 12px; }
  .bwm-form .form-text {
    font-size: 13px; }
  .bwm-form .catchphrase {
    text-align: center;
    font-size: 42px;
    position: absolute;
    color: #f1f1f1;
    padding: 40px;
    background-color: rgba(0, 0, 0, 0.5);
    margin-top: 50px;
    letter-spacing: 1px; }
  .bwm-form .btn-form {
    margin-top: 15px; }

.bwm-res-errors {
  margin: 10px 0; }
  .bwm-res-errors p {
    margin-bottom: 5px; }

#userBookings .card {
  margin-bottom: 20px; }

#userBookings h1 {
  margin-bottom: 20px; }

#userBookings .card-block {
  padding: 20px; }

#userBookings .card-header {
  text-transform: capitalize; }

#userBookings .card-title {
  font-size: 18px; }

#userBookings .booking-desc {
  font-size: 14px; }

#userBookings .booking-days {
  font-size: 14px;
  font-weight: bold; }

#userBookings .booking-price-value {
  font-weight: 700; }

#userBookings .btn-bwm {
  color: white;
  font-size: 14px;
  padding: 15px; }

.booking {
  padding: 20px;
  border: 1px solid #dedada; }
  .booking a:hover {
    color: white; }
  .booking .btn-confirm {
    font-size: 17px;
    font-weight: 700;
    padding: 13px; }
  .booking label {
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 0; }
  .booking-price {
    font-size: 22px;
    font-weight: 700; }
  .booking-per-night {
    font-size: 13px;
    font-weight: 300; }
  .booking-note-title {
    font-size: 14px;
    margin-bottom: 10px; }
  .booking-note-text {
    font-size: 13px;
    font-weight: 300; }
  .booking .should-login-btn-container {
    text-align: center;
    margin-bottom: 10px; }
    .booking .should-login-btn-container .btn-login {
      text-align: center;
      font-size: 15px;
      padding: 15px; }
      .booking .should-login-btn-container .btn-login:hover, .booking .should-login-btn-container .btn-login:focus {
        color: white;
        text-decoration: none; }

.booking-modal .title {
  font-weight: 700;
  font-size: 26px; }

.booking-modal .buttons {
  margin: 15px 0px; }

.booking-modal em {
  font-style: normal;
  font-size: 17px;
  font-weight: 700; }

.booking-modal p {
  margin: 0; }

.daterangepicker td.in-range {
  background-color: red #3c3c3c; }

.daterangepicker td.active {
  background-color: #3c3c3c !important; }

#categoryListing {
  margin-top: 15px;
  /* SINGLE CARD STYLES*/ }
  #categoryListing .title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px; }
  #categoryListing .service-detail-link:hover {
    text-decoration: none; }
  #categoryListing .bwm-card {
    border: none;
    font-family: 'Oswald'; }
    #categoryListing .bwm-card .card-img {
      height: 220px;
      width: 18vw;
      min-width: 220px;
      margin: 10px !important;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }
    #categoryListing .bwm-card .card-block {
      padding-top: 8px; }
      #categoryListing .bwm-card .card-block .card-subtitle {
        margin: 0;
        font-weight: 500;
        font-size: 12px;
        text-transform: uppercase; }
      #categoryListing .bwm-card .card-block .card-title {
        font-size: 19px;
        margin: 3px 0;
        text-align: center;
        color: #484848; }
      #categoryListing .bwm-card .card-block .card-text {
        font-size: 14px;
        font-weight: 300;
        color: #7e7e7e;
        margin-bottom: 0; }

#serviceDetail .upper-section {
  margin-bottom: 30px; }
  #serviceDetail .upper-section img {
    width: 100%; }

#serviceDetail .details-section {
  color: #484848; }
  #serviceDetail .details-section .service-type {
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
    margin: 0; }
  #serviceDetail .details-section .service-owner {
    float: right; }
    #serviceDetail .details-section .service-owner img {
      height: 40px;
      border-radius: 50%;
      display: block;
      margin: 0 auto; }
    #serviceDetail .details-section .service-owner span {
      display: block;
      font-size: 13px;
      font-weight: 300; }
  #serviceDetail .details-section .service-label {
    margin-right: 10px; }
  #serviceDetail .details-section .service-title {
    margin-bottom: 5px;
    font-size: 32px;
    font-weight: 700; }
  #serviceDetail .details-section .service-city {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 20px; }
  #serviceDetail .details-section .service-street {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 20px; }
  #serviceDetail .details-section .service-room-info {
    margin-bottom: 20px;
    font-weight: 300;
    font-size: 16px; }
    #serviceDetail .details-section .service-room-info .service-bedrooms {
      margin-right: 0; }
    #serviceDetail .details-section .service-room-info span {
      margin-right: 10px; }
    #serviceDetail .details-section .service-room-info i {
      margin-right: 2px; }
  #serviceDetail .details-section .service-description {
    font-weight: 300;
    margin-bottom: 30px; }
  #serviceDetail .details-section .service-assets .title {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 22px; }
  #serviceDetail .details-section .service-assets span {
    display: block;
    margin-bottom: 15px;
    font-weight: 300; }

#userRentals .toBeDeleted {
  border-color: red; }

#userRentals .card {
  margin-bottom: 20px; }

#userRentals .card-block {
  padding: 20px; }

#userRentals .card-title {
  font-size: 20px; }

#userRentals .btn-bwm {
  font-size: 14px;
  color: white;
  padding: 10px 15px; }

#userRentals .card-footer {
  font-size: 14px; }

#userRentals .btn-bookings {
  border-color: #3c3c3c;
  background-color: transparent;
  color: #3c3c3c; }
  #userRentals .btn-bookings:hover, #userRentals .btn-bookings:focus {
    border-color: #3c3c3c !important;
    background-color: transparent !important;
    color: #3c3c3c !important; }

#userRentals .delete-menu {
  margin-top: 5px; }

#userRentals .btn-danger, #userRentals .btn-success {
  margin-left: 10px;
  font-size: 14px; }

#userRentals .btn-warning {
  margin-left: 10px;
  font-size: 14px;
  color: white; }

#userRentals .btn-bwm {
  margin-right: 10px; }

.service-booking-modal .btn-bwm {
  font-size: 14px;
  color: white;
  padding: 10px 15px; }

.service-booking-modal .bookings-inner-container p {
  font-size: 14px;
  margin-bottom: 0px; }

.editableComponent .btn-editable {
  margin-left: 10px;
  font-size: 9px; }

.editableComponent .btn-editable-image {
  margin: 10px; }

.img-upload-container {
  margin-bottom: 20px;
  position: relative;
  max-height: 200px; }
  .img-upload-container .img-preview-container {
    background-color: #f7f7f7; }
  .img-upload-container .img-preview {
    background: center center no-repeat;
    background-size: contain;
    height: 116px;
    width: 116px; }
  .img-upload-container .ReactCrop {
    max-height: 400px; }
  .img-upload-container .img-upload {
    margin-right: 20px;
    margin-bottom: 0;
    border: 1px solid grey; }
  .img-upload-container .upload-text {
    font-size: 17px;
    margin: 0 10px 0 0; }
  .img-upload-container input {
    display: none; }
  .img-upload-container .alert {
    margin-top: 10px; }
  .img-upload-container .img-loading-overlay {
    background-color: black;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    opacity: .2;
    position: absolute; }
  .img-upload-container .img-spinning-circle {
    display: inline-block;
    width: 64px;
    height: 64px;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }
  .img-upload-container .img-spinning-circle:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite; }

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.accountDetail {
  font-family: 'Oswald'; }
  .accountDetail #accountUserForm {
    border: 5px solid #f3f3f3;
    padding: 20px;
    margin-bottom: 20px; }
  .accountDetail #accountDetailForm {
    border: 5px solid #f3f3f3;
    padding: 20px;
    margin-bottom: 40px; }
  .accountDetail .upper-section {
    margin-bottom: 30px; }
    .accountDetail .upper-section img {
      width: 100%; }
  .accountDetail .national-id {
    width: 100%;
    height: 180px; }
  .accountDetail .details-section {
    color: #484848; }
    .accountDetail .details-section .account-type {
      font-size: 14px;
      font-weight: 300;
      text-transform: uppercase;
      margin: 0; }
    .accountDetail .details-section .account-owner {
      float: right; }
      .accountDetail .details-section .account-owner img {
        height: 40px;
        border-radius: 50%;
        display: block;
        margin: 0 auto; }
      .accountDetail .details-section .account-owner span {
        display: block;
        font-size: 13px;
        font-weight: 300; }
    .accountDetail .details-section .account-label {
      margin-right: 10px; }
    .accountDetail .details-section .account-title {
      margin-bottom: 5px;
      font-size: 32px;
      font-weight: 700; }
    .accountDetail .details-section .account-city {
      font-size: 14px;
      font-weight: 300;
      margin-bottom: 20px; }
    .accountDetail .details-section .account-street {
      font-size: 14px;
      font-weight: 300;
      margin-bottom: 20px; }
    .accountDetail .details-section .account-room-info {
      margin-bottom: 20px;
      font-weight: 300;
      font-size: 16px; }
      .accountDetail .details-section .account-room-info .service-bedrooms {
        margin-right: 0; }
      .accountDetail .details-section .account-room-info span {
        margin-right: 10px; }
      .accountDetail .details-section .account-room-info i {
        margin-right: 2px; }
    .accountDetail .details-section .account-description {
      font-weight: 300;
      margin-bottom: 30px; }
    .accountDetail .details-section .account-assets .title {
      margin-bottom: 10px;
      font-weight: 700;
      font-size: 22px; }
    .accountDetail .details-section .account-assets span {
      display: block;
      margin-bottom: 15px;
      font-weight: 300; }

.map {
  margin-top: 25px;
  margin-bottom: 40px;
  font-family: 'Oswald';
  background-color: white;
  text-align: 'justify'; }
  .map .map-location .map-title {
    margin: -25px 0 20px 0;
    font-size: 35px;
    color: black;
    text-align: center; }
  .map .map-location .map-shadow-title {
    font-size: 45px;
    color: #ebebeb;
    text-align: center; }
  .map .map-location .map-details {
    font-size: 18px;
    color: #464646;
    text-align: center; }
  .map .map-image {
    margin-top: 50px;
    height: "auto";
    width: "100%"; }

.contact {
  margin-top: 0px;
  font-family: 'Oswald';
  background-color: white;
  text-align: 'justify'; }
  .contact .contact-title {
    margin: -45px 0 40px 0;
    font-size: 35px;
    color: black;
    text-align: center; }
  .contact .contact-shadow-title {
    margin-top: 20px;
    font-size: 45px;
    color: #ebebeb;
    text-align: center; }
  .contact .contact-details {
    font-size: 18px;
    color: #464646;
    text-align: center; }

/* Colours */
.item-listing {
  /* table */ }
  .item-listing table thead th {
    border: 0; }
  .item-listing table {
    width: 100%; }
  .item-listing tbody td {
    background-color: #ededed;
    border: 1px solid #d1d1d1;
    text-align: center;
    padding: 1.25rem; }
  .item-listing th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #707070;
    color: white; }
  .item-listing td:first-child {
    text-align: left; }
  .item-listing tr:nth-child(even) {
    background-color: #f2f2f2; }
  .item-listing tr:hover {
    background-color: #ddd; }

body {
  overflow-x: hidden; }

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out; }

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem; }

#sidebar-wrapper .list-group {
  width: 15rem; }

#page-content-wrapper {
  min-width: 100vw; }

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0; }

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0; }
  #page-content-wrapper {
    min-width: 0;
    width: 100%; }
  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem; } }

#as-react-datatable-container select {
  width: 70px !important; }

.ReactCrop__image {
  width: 550px;
  height: 300px; }

.img-preview-container {
  margin-bottom: 20px; }

html {
  scroll-behavior: smooth; }

body {
  font-family: 'Oswald'; }

.container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
  width: 100%;
  padding-right: 0px !important;
  padding-left: 0px !important; }

.clickable:hover {
  cursor: pointer; }

.page-title {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 20px; }

.col-extra-space {
  margin-top: 20px; }

.page-area {
  margin-top: -40px; }

.apartment {
  color: #a72da3; }

.house {
  color: #bf1818; }

.condo {
  color: #d68000; }

.btn-bwm {
  margin-right: 10px;
  background-color: #3c3c3c;
  border-color: #3c3c3c;
  color: white;
  padding: 15px 30px;
  font-size: 20px; }
  .btn-bwm:hover, .btn-bwm:focus, .btn-bwm:active {
    border-color: #3c3c3c !important;
    background-color: #3c3c3c !important; }
